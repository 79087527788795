import { graphql } from 'gatsby';
import * as React from 'react';
import * as SectionComponents from 'src/components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';

function capitalizeString(str) {
  if (typeof str !== 'string' || !str) throw new Error('Input not valid');
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function resolveSections(section) {
  if (section && section._type) {
    const Section = SectionComponents[capitalizeString(section._type)]; // eslint-disable-line import/namespace
    if (Section) {
      return Section;
    }
  }

  // TODO - error/type handling missing.
  return null;
}

function landingPage({ data, location }) {
  const seo = {
    title: data.sanityFlexiblePage.pageSeo?.title ?? data.sanityFlexiblePage.title,
    description: data.sanityFlexiblePage.pageSeo?.description,
    image: data.sanityFlexiblePage.pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <div className="bg-shapes-banner">
        {data.sanityFlexiblePage.flexibleComponent.map((section) => {
          if (section) {
            const SectionComponent = resolveSections(section);
            if (!SectionComponent) {
              return <div>Missing section {section._type}</div>;
            }

            if (section._type === 'blogPreview') {
              const posts = data.allSanityBlogPost.nodes.filter((post) => post.category?.id === section.category.id);
              if (posts.length === 0) {
                throw new Error(`No posts under category "${section.category.name}".`);
              }

              return <SectionComponent {...section} posts={posts} key={section._key} />;
            }

            return <SectionComponent {...section} key={section._key} />;
          }
          return null;
        })}
      </div>
    </PageLayout>
  );
}

export default landingPage;

// TODO - Make gql fragments wherever possible, to remove code repetition. For example: "SanityLink"
export const query = graphql`
  query LandingPageQuery($id: String!) {
    sanityFlexiblePage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageSeo {
        ...getPageSeo
      }
      flexibleComponent {
        ... on SanityCards {
          _key
          _type
          cards {
            title
            description
            icon
            _type
            _key
          }
          extraContent {
            title
            description
            _type
            _key
          }
          design
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanitySimpleCarousel {
          _key
          _type
          isDark
          images {
            alt
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, width: 748, height: 480, placeholder: BLURRED)
                _key
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityComplexCarousel {
          _key
          _type
          isDark
          hasCta
          sectionsWithImageAndLink {
            _key
            title
            description: _rawDescription
            links {
              _key
              ... on SanityLink {
                _key
                title
                url
              }
            }
            image {
              alt
              image {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH, width: 748, height: 480, placeholder: BLURRED)
                  _key
                }
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityBlocksWithImage {
          _key
          _type
          paragraphsWithImage {
            title
            paragraphs
            _type
            image {
              alt
              image {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityOurValues {
          _key
          _type
          ourValues {
            ...getOurValues
          }
        }

        ... on SanityHero {
          _key
          _type
          hero {
            ...getHero
          }
        }

        ... on SanityInNumbers {
          _key
          _type
          inNumbers {
            ...getInNumbers
          }
        }

        ... on SanityPartners {
          _key
          _type
          partners {
            ...getPartners
          }
        }

        ... on SanityProudOf {
          _key
          _type
          proudOf {
            ...getProudOf
          }
        }

        ... on SanityOurProcess {
          _key
          _type
          ourProcess {
            ...getOurProcess
          }
        }

        ... on SanityTestimonial {
          _key
          _type
          testimonial {
            ...getTestimonial
          }
        }

        ... on SanityHistory {
          _key
          _type
          history {
            ...getHistory
          }
        }

        ... on SanityBlockWithImageAndCta {
          _key
          _type
          image {
            alt
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, width: 460, height: 480, placeholder: BLURRED)
                _key
              }
            }
          }
          link {
            ... on SanityLink {
              title
              url
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityIconsSection {
          _key
          _type
          sections {
            _key
            title
            description: _rawDescription
            image {
              alt
              image {
                asset {
                  gatsbyImageData(height: 100, placeholder: BLURRED)
                  _key
                }
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithImage {
              title
              tagline
              image {
                _key
                alt
                image {
                  asset {
                    gatsbyImageData(placeholder: BLURRED)
                    _key
                  }
                }
              }
            }
          }
        }

        ... on SanityIconsSectionAndCards {
          _key
          _type
          cards {
            icon
            title
            description
            _type
            _key
          }
          sections {
            _key
            title
            description: _rawDescription
            image {
              alt
              image {
                asset {
                  gatsbyImageData(width: 110, placeholder: BLURRED)
                  _key
                }
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithImage {
              title
              tagline
              image {
                _key
                alt
                image {
                  asset {
                    gatsbyImageData(width: 175, fit: MIN, placeholder: BLURRED)
                    _key
                  }
                }
              }
            }
          }
        }

        ... on SanityHighlightColumns {
          _key
          _type
          image {
            alt
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                _key
              }
            }
          }
          columns {
            _key
            title
            description: _rawDescription
            links {
              _key
              ... on SanityLink {
                _key
                title
                url
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityHighlightTabs {
          _key
          _type
          tabs {
            _key
            buttonLabel
            content {
              image {
                alt
                image {
                  asset {
                    gatsbyImageData(width: 570, placeholder: BLURRED)
                    _key
                  }
                }
              }
              title
              description: _rawDescription
              links {
                _key
                ... on SanityLink {
                  _key
                  title
                  url
                }
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityFaq {
          _key
          _type
          faq {
            ...getFaq
          }
        }

        ... on SanityBlogPreview {
          _key
          _type
          category {
            id
            name
          }
        }

        ... on SanityPackageTiers {
          _key
          _type
          tiers {
            id: _key
            title
            tagline
            isDark
            features
            link {
              ... on SanityLink {
                title
                url
              }
            }
          }
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
        }

        ... on SanityBlockWithVideo {
          _key
          _type
          sectionHeader {
            ... on SanityHeaderWithDesc {
              title
              tagline
              description
            }
          }
          youtubeVideoLink
        }

        ... on SanityStandaloneVideo {
          _key
          _type
          youtubeVideoLink
        }

        ... on SanityBenefits {
          _key
          _type
          benefits {
            ...getBenefits
          }
        }

        ... on SanityLifeAt {
          _key
          _type
          lifeAt {
            ...getLifeAt
          }
        }

        ... on SanitySimpleBlock {
          _key
          _type
          body: _rawBody
        }

        ... on SanitySimpleHalfBlocks {
          _key
          _type
          halfBlocks {
            description: _rawDescription
          }
        }

        ... on SanityCta {
          _key
          _type
          cta {
            ...getCta
          }
        }

        ... on SanityCtaWithAnimation {
          _key
          _type
          ctaWithAnimation {
            ...getCtaWithAnimation
          }
        }
      }
    }

    allSanityBlogPost(sort: { order: DESC, fields: publicationDate }) {
      nodes {
        id
        title
        slug {
          current
        }
        publicationDate
        author {
          name
          image {
            asset {
              gatsbyImageData(width: 65, height: 65, placeholder: BLURRED)
            }
          }
        }
        category {
          id
          name
          color
        }
        postImage {
          alt
          image {
            asset {
              gatsbyImageData(width: 600, height: 480, placeholder: BLURRED)
            }
          }
        }
        description
      }
    }
  }
`;
